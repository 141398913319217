@import "~css/colors";
@import "~css/layout";
@import "~css/media";
@import "~css/transitions";
@import "~css/typography";
@import "~css/shared/toast";

.ApplyToggle {
  background-color: none;
  margin-bottom: map-get($map: $margin, $key: xlarge);

  button {
    @include responsive_font(16px, 18px);

    background-color: map-get($map: $colors-background, $key: accent-gray);
    border: 0;
    color: map-get($map: $colors-text, $key: accent-gray-text);
    cursor: pointer;
    font-weight: bold;
    outline: 0;
    padding: map-get($map: $padding, $key: xlarge) - 1px;
    width: 50%;

    &:hover {
      background-color: darken(
        map-get($map: $colors-background, $key: accent-gray),
        2.5%
      );
    }

    &.isActive {
      background-color: map-get($map: $colors-background, $key: white);
      color: map-get($map: $colors-text, $key: black);
    }

    &:first-of-type {
      border-top-left-radius: map-get($map: $border-radius, $key: large);
    }

    &:last-of-type {
      border-top-right-radius: map-get($map: $border-radius, $key: large);
    }
  }
}

.Form {
  background-color: map-get($map: $colors-background, $key: white);
  border-radius: map-get($map: $border-radius, $key: large);
  margin-bottom: map-get($map: $margin, $key: xlarge);
  padding-bottom: map-get($map: $padding, $key: xlarge);

  @include desktop {
    padding: 0 0 map-get($map: $padding, $key: xlarge);
  }

  .FormRow {
    padding-left: map-get($map: $padding, $key: xlarge);
    padding-right: map-get($map: $padding, $key: xlarge);

    @include desktop {
      padding-left: map-get($map: $padding, $key: xlarge);
      padding-right: map-get($map: $padding, $key: xlarge);
    }
  }

  .FormRowNames {
    @include big_mobile {
      display: flex;
      justify-content: space-between;
    }
  }

  .FirstName {
    flex: 1 1 auto;
    margin-bottom: 0;
    margin-top: 0;

    @include big_mobile {
      margin-right: map-get($map: $margin, $key: default);
    }
  }

  .LastName {
    flex: 1 1 auto;
    margin-bottom: 0;

    @include big_mobile {
      margin-top: 0;
    }
  }

  .SubmitReferralButton {
    width: 100%;
  }
}

.NotLoggedInMessage {
  padding: 0 map-get($map: $margin, $key: xlarge);
  text-align: center;
}

.NotLoggedInBox {
  background-color: map-get($map: $colors-background, $key: accent-gray);
  border-radius: map-get($map: $border-radius, $key: large);
  margin-bottom: map-get($map: $margin, $key: large);
  padding: map-get($map: $margin, $key: xlarge);
}
